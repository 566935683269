.timeTravel {
  & .dateRanges,
  .controls {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    & .buttons {
      display: flex;
      gap: 8px;
    }
  }

  & .dateRanges {
    padding: 24px 0;
  }
}
