.logo-image {
  display: block;
}

.logo-image > div {
  display: flex;
}

.logo-image.collapsed {
  width: 54px;
}

.logo-image img {
  max-width: inherit;
  width: 191px;
}

.logo-image.collapsed img {
  width: 45px;
}

/* query builder */
.ant-tag .anticon {
  vertical-align: middle;
}

/* uploads list */
.col-hdr-nowrap {
  white-space: nowrap;
}

.body-section .single-image:hover .single-image-name {
  bottom: 0;
  top: auto;
}

.body-section.expanded {
  left: 0;
  width: 100%;
}

.body-section .loading-more {
  display: block;
}

.body-section .loading-more.hidden {
  display: none;
}

.body-section {
  left: 0;
  overflow: auto;
}

.ant-btn > span[role="img"] {
  display: inline-flex;
}

.ant-form fieldset {
  border: 1px solid #d9d9d9;
  padding: 0 16px;
}

.ant-form fieldset legend {
  border: none;
  padding: 3px 8px 0;
  margin-bottom: 0;
  margin-left: -8px;
  width: unset;
}

.ant-btn-primary,
.ant-btn-primary.ant-btn-dangerous {
  box-shadow: none;
}

body {
  font-size: 14px;
  line-height: 20px;
}

h1[class^="ant-typography"] {
  font-weight: 400;
  text-transform: uppercase;
}

h2[class^="ant-typography"] {
  font-weight: 400;
}

h3[class^="ant-typography"] {
  font-weight: 400;
}

h4[class^="ant-typography"] {
  font-weight: 400;
}

.navbar-title {
  display: flex;
  align-items: center;
}

.navbar-title .ant-typography {
  margin-bottom: 0;
}

.ant-layout-header {
  background-color: #fbfbfb;
  padding: 0 16px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0;
}

/* .ant-layout .ant-layout-sider-dark,
.dark .ant-layout-header,
.ant-menu-dark,
.ant-layout .ant-layout-sider-trigger {
  background-color: #141414;
} */

.ant-input,
.ant-input-textarea-show-count {
  background-color: #fff;
  border-color: #d8d8d9;
  border-radius: 0;
}

.ant-input-search .ant-input {
  background-color: unset;
}

.ant-input-number {
  border-radius: 0;
}

.ant-modal .ant-input {
  background-color: unset;
}

.ant-form {
  padding: 24px;
}

.ant-layout-sider .ant-form,
.ant-drawer .ant-form,
.ant-modal .ant-form {
  background-color: unset;
  padding: 0;
}

.ant-modal .ant-select-selector {
  background-color: transparent !important;
}

.ant-btn-primary.ant-btn:disabled,
.ant-btn-primary.ant-btn-dangerous:disabled {
  border-color: #d8d8d9;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #76b8b4;
}

/* .ant-typography {
  font-size: 17px;
  line-height: 24px;
} */

.ant-descriptions .ant-descriptions-title,
.side-panel-title {
  font-size: 19px;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
}

.side-panel-title {
  margin-bottom: 20px;
}

.ant-select-item-option-selected {
  background-color: unset !important;
}

.ant-descriptions .ant-descriptions-view {
  border-radius: 0;
}

#tutor aside {
  max-height: calc(100vh - 72px);
}

.ant-layout-footer {
  background-color: #fbfbfb;
  color: var(--grey);
  text-transform: uppercase;
}

/* .ce-block__content,
.ce-toolbar__content {
  max-width: none;
} */

.color-circle {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.graph-legend {
  bottom: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.graph-legend fieldset {
  background-color: #fbfbfb;
  border: 1px solid #d6d6e7;
  padding: 0 16px 16px;
  width: 214px;
}

.graph-legend div > label:first-child {
  float: left;
  line-height: 32px;
  margin-top: 5px;
  width: 165px;
}

.graph-legend div > div {
  margin-top: 5px;
}

.graph-legend .ant-checkbox-wrapper {
  margin-top: 5px;
}

.chat .ant-checkbox-wrapper {
  display: flex;
  margin-bottom: 16px;
  width: 100%;
}

.chat .content .ant-checkbox-wrapper {
  margin-bottom: 0;
}

.chat .ant-checkbox-wrapper > span {
  width: 100%;
}

.chat .ant-checkbox-wrapper > span.ant-checkbox {
  width: auto;
}

.chat .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: unset;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#chat-component .chat {
  top: unset;
  bottom: 0;
}

#chat-component .chat-actions .ant-btn {
  font-size: 12px;
}

#chat-component .ant-mentions-outlined:focus-within {
  background: #f5f5f5;
  box-shadow: none;
}

@media (width > 2057px) {
  .chat {
    max-width: 1500px;
  }
}

.chat .ant-radio-group > * {
  margin-bottom: 8px;
}

.chatline {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25%;
}

.chatline.assistant {
  margin-right: 100px;
}

.chatline.user {
  margin-left: 100px;
}

.chatline > * {
  margin-right: 8px;
}

.chatline > *:last-child {
  margin-right: 0;
}

.chatline .ant-radio {
  width: 16px;
}

.chatline .avatar {
  width: 32px;
}

.chatline .content {
  flex: 1;
  font-size: 14px;
  background: gainsboro;
  padding: 16px 8px;
  border-radius: 16px;
}

/* .chatline.assistant .content {
  background: #F1E8D4;
} */

.chatline.assistant .content > div {
  overflow-x: scroll;
}

.chatline.assistant .ant-btn-primary {
  color: #f1e8d4;
}

.chatline.assistant .ant-btn.ant-btn-primary:hover {
  color: #fff !important;
}

.chatline.assistant .ant-btn {
  border: none !important;
}

.chatline.assistant .carousel .ant-btn:hover {
  color: #333 !important;
}

.chatline.user .content {
  text-align: left;
}

.chatline.user .content > div {
  display: inline-block;
  text-align: left;
}

.chat-sep {
  border-left: 1px solid rgba(5, 5, 5, 0.06);
  padding-left: 24px;
}

.chat-actions {
  display: flex;
  flex-wrap: wrap;
}

.chat-actions > * {
  margin-bottom: 16px;
  margin-right: 8px;
}

.ant-mentions {
  border: 0;
}

.management-plan {
  font-size: 17px;
  max-width: 1500px;
  margin-bottom: 40px;
}

.management-plan li,
.management-plan p {
  margin: 16px 0;
}

.management-plan h2 {
  font-weight: 600;
  margin-top: 32px;
  font-size: 1.3em;
}

.management-plan h3 {
  font-weight: 600;
  margin-top: 24px;
  font-size: 1.1em;
}

.management-plan ul,
.management-plan ol {
  margin-left: 1em;
}

.table td {
  border: 1px solid #f0f0f0;
  border-bottom: 1px solid #ccc;
  padding: 4px 8px;
  vertical-align: top;
}

.table tbody tr:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fbfbfb;
}

/* li a {
  color: #009c9c;
} */

#tags-input .ant-select-single {
  height: unset;
}

.markdown {
  font-size: 16px;
  line-height: 24px;
}

.markdown p {
  margin-bottom: 8px;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  font-weight: 600;
  margin-bottom: 8px;
}

.markdown h1 {
  font-size: 1.25em;
}

.markdown h2 {
  font-size: 1.15em;
}

.markdown h3 {
  font-size: 1.1em;
}

.markdown ul,
.markdown ol,
.FlashcardWrapper__item--content ul,
.FlashcardWrapper__item--content ol {
  margin-bottom: 12px;
  margin-top: -4px;
  padding-left: 14px;
}

.FlashcardWrapper__item--content ul,
.FlashcardWrapper__item--content ol {
  padding-left: 40px;
}

.hidden {
  visibility: hidden;
  height: 0;
}

.ant-table-wrapper .ant-table {
  border-radius: 0;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-top-width: 0;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
  z-index: 11;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > * {
  border-top: 1px solid #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.text-secondary {
  font-size: 14px;
  color: rgba(60, 61, 65, 0.45);
}

.ant-input::placeholder {
  color: rgba(60, 61, 65, 0.45);
}

.ant-typography-copy {
  align-self: center;
}

.cell {
  border-top: 1px solid #e2e8f0;
  padding-bottom: 24px;
  padding-top: 8px;
}

.cells {
  border: 1px solid #e2e8f0;
  margin-bottom: 24px;
  padding: 16px;
}

.cells .cell:first-child {
  border: 0;
  padding-top: 0;
}

.nowrap {
  white-space: nowrap;
}

.ant-modal-body .table td {
  max-width: 400px;
}

/* Workspace selection menu on Navbar */
.selected-entity {
  display: flex;
  align-items: center;
  line-height: 32px;
}

.selected-entity.last {
  padding-right: 24px;
}

.selected-entity > div {
  color: rgba(60, 61, 65, 0.88);
  padding: 0;
}

.selected-entity .anticon {
  vertical-align: sub;
}

.ant-menu-light .ant-menu-item.ant-menu-item-active,
.ant-menu-light .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.15);
}

.ant-layout {
  background: #fbfbfb;
}

#transcript .ant-typography {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

#tools .ant-card .ant-card-body {
  padding: 16px;
}

#tools .ant-card .ant-card-meta-description {
  font-size: 12px;
  height: 38px;
}

.chat .ant-typography {
  margin-bottom: 0;
}

.break {
  border: 0;
  border-bottom: 1px solid #d8d8d9;
  border-radius: 0;
  background: inherit;
  width: 900px;
}

.bubble {
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border-radius: 16px;
}

.bubble:before {
  content: "";
  position: absolute;
  top: 73px;
  left: 0;
  height: 20px;
  width: 20px;
  transform: rotate(45deg) translate(-55%);
  background: #ffffff;
}

.bubble blockquote {
  font-size: 14px;
  font-style: italic;
  width: 200px;
}

.ant-mentions {
  background: inherit;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  font-size: 24px;
  line-height: 24px;
  padding-bottom: 5px;
}

.ant-typography .ant-typography-copy {
  color: rgba(60, 61, 65, 0.45);
  font-size: 13px;
  margin-left: 8px;
}

.chat-card {
  padding: 16px;
  background: #fbfbfb;
  border-radius: 16px;
  width: 232px;
}

.chat-card p,
.chat-card ol,
.chat-card ul {
  margin-bottom: 0;
}

.chat-card blockquote {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
  width: 200px;
}

.chat .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgba(60, 61, 65, 0.45);
}

.chat .ant-typography {
  font-size: 16px;
}

.chatline.user .ant-typography {
  color: #fbfbfb;
}

.chatline.assistant .ant-btn-link {
  color: rgba(60, 61, 65, 0.45);
  font-family: inherit;
  font-size: 13px;
}

.chatline.assistant .ant-btn-link:hover,
.chatline .ant-typography .ant-typography-copy:hover {
  color: rgba(60, 61, 65, 0.88) !important;
}

.chatline.assistant .ant-btn-link:active,
.chatline.assistant .ant-btn-link:focus,
.chatline .ant-typography .ant-typography-copy:active,
.chatline .ant-typography .ant-typography-copy:focus {
  color: rgba(60, 61, 65, 0.45);
}

.chatline .ant-typography .ant-typography-copy-success {
  color: #039c9c !important;
}

.chatline.user {
  margin-top: 16px;
}

.chatline.assistant {
  margin-top: 16px;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]
  .ant-steps-item-description {
  color: inherit;
}

.ant-steps-item-custom .ant-steps-item-icon {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  height: 32px !important;
  width: 32px !important;
}

.ant-steps-item-custom .ant-steps-icon {
  border-radius: 32px !important;
  font-size: 14px !important;
  line-height: 32px !important;
  color: red !important;
}

.chat .ant-space-item {
  display: flex;
  align-self: stretch;
  /* min-height: 159px; */
}

.ant-tag {
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ant-space .ant-tag {
  margin-bottom: 8px;
}

.ant-table-cell .ant-space .ant-tag {
  margin: 0;
}

.ant-table-cell ol {
  margin-left: 1em;
}

.message-scroller {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 24px;
  padding-right: 12px;
}

.tutor-content .message-scroller {
  display: flex;
  align-items: flex-end;
}

.message-scroller > div {
  max-height: 100%;
}

.ant-popover-content {
  background: none;
  border-radius: 0;
  max-width: 700px;
  padding: 0;
}

blockquote {
  font-size: 1.1em;
  font-style: italic;
  color: #555555;
  padding: 0 16px 1.2em 16px;
  line-height: 1.6;
  position: relative;
  background: #ededed;
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  font-size: 4em;
  position: absolute;
  left: -5px;
  top: -32px;
}

blockquote::after {
  content: "";
}

blockquote span {
  display: block;
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

blockquote .ant-typography {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
}

.ant-popover .ant-popover-inner,
.ant-popover .ant-popover-arrow:before {
  background: #ededed;
}

.ant-popover .ant-popover-arrow::before {
  width: 16px;
  height: 13px;
}

.chatline.assistant .ant-avatar {
  /* background: #FF3317; */
  background: #7d51da;
}

.chatline.user .ant-avatar {
  background: gainsboro;
}

.FlashcardArrayWrapper {
  width: 548px !important;
  gap: 8px;
}

.FlashcardArrayWrapper__CardHolder {
  height: 218px !important;
}

.flashcards-header {
  font-size: 1.2em;
  margin: 24px 0 8px 0;
  font-weight: 600;
}

.FlashcardWrapper__item {
  background: linear-gradient(
    180deg,
    rgba(54, 90, 127, 1) 0%,
    rgba(0, 187, 255, 1) 100%
  );
  color: #fff;
}

.ant-btn-variant-outlined.action-btn:not(:disabled):not(
    .ant-btn-disabled
  ):hover,
.FlashcardWrapper__item--back .FlashcardWrapper__item--content {
  background: #ff3317;
  background: linear-gradient(
    180deg,
    rgba(255, 51, 23, 1) 0%,
    rgba(255, 104, 0, 1) 100%
  );
}

.ant-btn-variant-outlined.action-btn:not(:disabled):not(.ant-btn-disabled) {
  border: 0;
  font-size: 1.2em;
}

.ant-btn-variant-outlined.action-btn:not(:disabled):not(.ant-btn-disabled),
.FlashcardWrapper__item--content {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 104, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 104, 0, 1) 0%,
    rgba(255, 51, 23, 1) 100%
  );
  color: #fff;
}

.FlashcardWrapper__item--content p {
  font-size: 1.2em;
  margin: 0 40px;
}

.FlashcardArrayWrapper__controls {
  height: 24px;
}

.speaker {
  color: #333333;
  font-size: 1.1em;
  font-weight: bold;
  min-height: 24px;
}

.circular-menu button {
  border-color: #ff3317 !important;
  background: gainsboro;
}

.circular-menu button > div {
  background: #ff3317;
}

.circular-menu button:is(:hover, :focus, :active) {
  background: #ff3317 !important;
}

.circular-menu-item {
  color: #ff3317 !important;
}

.circular-menu-item:is(:hover, :focus, :active) {
  color: #fff !important;
}

.podcasts .slick-slide > div {
  margin: 16px 174px 32px;
}

.people .slick-slide > div {
  margin: 8px 32px 32px;
  height: 210px;
}

.people .ant-carousel .slick-list .slick-slide > div > div {
  min-height: 145px;
}

.carousel {
  width: 548px;
  background: #ededed;
}

.slick-slider button {
  color: #000 !important;
}

.ant-carousel .slick-dots li button {
  background: #000 !important;
}

.user-checkbox .ant-checkbox {
  margin-top: 16px;
  margin-left: 8px;
}

.notes .ant-card {
  background: #f1e8d4;
  border: none;
  width: 234px;
}

.notes .ant-card-body {
  padding: 16px 10px;
}

.notes .ant-card-meta-title {
  font-size: 14px;
}

.notes .body-section .single-image {
  width: 234px;
  height: auto;
  margin: 0;
}

.notes .body-section .image-section {
  padding: 0;
}

@media (min-width: 320px) {
  #menu {
    display: none;
  }

  .navbar-title .ant-typography {
    font-size: 24px;
    margin-top: -5px;
  }

  .tutor-content {
    display: block !important;
  }

  .submenu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .submenu button {
    justify-content: start;
  }

  .tutor-content .chat {
    width: 100% !important;
    margin: 0 !important;
    max-height: calc(-104px + 100vh) !important;
    padding-right: 16px;
  }

  .chat {
    margin-left: auto !important;
    margin-right: auto !important;
    max-height: calc(-104px + 100vh) !important;
  }

  .tutor-content .chatline.assistant {
    margin-right: auto;
  }

  #login {
    justify-content: center;
  }

  #login .ant-card {
    margin-left: 0 !important;
    width: 325px !important;
  }

  #login .ant-card-body {
    padding: 24px 0;
  }

  .FlashcardArrayWrapper {
    width: 273px !important;
  }

  .slick-slide {
    width: 273px;
  }

  .carousel {
    width: 273px;
  }

  .podcasts .slick-slide > div {
    margin: 16px 36px 32px;
  }

  .chatline .content .ant-tag {
    width: 245px !important;
  }

  .chatline .content .ant-tag > span:last-child > span {
    display: inline-block;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .hit .ant-popover-inner-content > div {
    width: 250px !important;
  }

  #tutor aside {
    height: fit-content;
    margin-right: -16px;
    max-height: calc(-104px + 100vh);
    padding: 0 !important;
  }

  #tutor #imagesContent > div {
    height: calc(100vh - 306px);
    overflow-y: auto;
    scrollbar-width: thin;
  }

  #tutor .notes-actions {
    justify-content: flex-end;
    width: 100%;
  }

  header {
    padding: 0 8px !important;
  }

  main {
    margin: 0 8px !important;
  }

  main main {
    margin: 0 !important;
  }

  .message-scroller {
    margin-bottom: 0 !important;
    padding-right: 24px !important;
  }

  .chat-actions > * {
    margin-bottom: 8px;
  }

  #app {
    overflow: hidden;
  }

  .ant-mentions-affix-wrapper textarea {
    font-size: 18px !important;
  }

  .flashcards-header {
    margin-top: 16px;
  }

  .chatline.user {
    /* margin-left: 0 !important; */
    margin-left: 0;
  }

  .ant-segmented-vertical {
    width: 290px;
  }
}

@media (min-width: 768px) {
  #menu {
    display: block;
  }

  .navbar-title .ant-typography {
    font-size: 32px;
    margin-top: 0;
  }

  .tutor-content {
    display: flex !important;
  }

  .tutor-content .chat {
    width: 650px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-height: calc(-72px + 100vh) !important;
    padding-right: 0;
  }

  .chat {
    margin-left: auto !important;
    margin-right: auto !important;
    max-height: calc(-104px + 100vh) !important;
  }

  .tutor-content .chatline.assistant {
    margin-right: 100px;
    width: 536px;
  }

  #login {
    justify-content: unset;
  }

  #login .ant-card {
    margin-left: 20% !important;
    width: 500px !important;
  }

  #login .ant-card-body {
    padding: 24px;
  }

  .FlashcardArrayWrapper {
    width: 448px !important;
  }

  .slick-slide {
    width: unset;
  }

  .carousel {
    width: 448px;
  }

  .podcasts .slick-slide > div {
    margin: 8px 64px 32px;
  }

  .chatline .content .ant-tag {
    width: 383px !important;
  }

  .chatline .content .ant-tag > span:last-child > span {
    display: inline-block;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .hit .ant-popover-inner-content > div {
    width: 406px !important;
  }

  #tutor aside {
    height: unset;
    margin-right: 10px;
    max-height: calc(-140px + 100vh);
    padding: unset !important;
  }

  #tutor #imagesContent > div {
    height: calc(100vh - 306px);
    overflow-y: auto;
  }

  header {
    padding: 0 16px !important;
  }

  main {
    margin: 0 16px !important;
  }

  main main {
    margin: 0 !important;
  }

  .message-scroller {
    margin-bottom: 0 !important;
    padding-right: 24px !important;
    scrollbar-width: thin;
  }

  .chat-actions > * {
    margin-bottom: 8px;
  }

  #app {
    overflow: hidden;
  }

  .ant-mentions-affix-wrapper textarea {
    font-size: 24px !important;
  }

  .flashcards-header {
    font-size: 1.2em;
    margin-top: 16px;
  }

  .chatline.user {
    /* margin-left: 100px !important; */
    margin-left: 100px;
  }

  .ant-segmented-vertical {
    width: 350px;
  }
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-cell-fix-right {
  background: #fbfbfb;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: #f5f5f5;
}

.ant-table-cell.selected {
  border-inline-end: 1px solid #bababa !important;
}

#graph-table .ant-table {
  margin-top: 24px;
}

.add-column-btn svg {
  fill: rgba(60, 61, 65, 0.25);
}

.add-column-btn:hover svg {
  fill: unset;
}

.ant-select-selector,
.ant-input-outlined:focus,
.ant-input-search .ant-input:focus,
.ant-input-outlined:focus-within {
  border-color: #d8d8d9 !important;
  box-shadow: none !important;
}

.ant-select input {
  outline: none !important;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  inset-inline: 10px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 6px;
}

.expanded .ant-select-selection-overflow-item {
  width: 100%;
}

.expanded .ant-select-selection-item-content {
  cursor: grab;
}

.in-chat-menu-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 104, 0, 1) 0%,
    rgba(255, 51, 23, 1) 100%
  );
  color: #f1e8d4;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  height: 100px;
  width: 144px;
}

.chatline.assistant .ant-btn.in-chat-menu-btn:hover {
  color: #fff !important;
}

.in-chat-menu-btn:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 51, 23, 1) 0%,
    rgba(255, 104, 0, 1) 100%
  ) !important;
  border: 0 !important;
  color: #fff !important;
}

#tutor.ant-layout .ant-layout-sider-light {
  background: #fbfbfb !important;
}

.chatline.user .ant-typography-copy {
  color: gainsboro;
}

body {
  --radial-menu-background: #f5f5f5;
  --radial-menu-color: rgba(60, 61, 65, 0.88);
  --radial-menu-border: #fbfbfb;
  --radial-menu-active-color: rgba(60, 61, 65, 0.88);
  --radial-menu-active-background: rgba(0, 0, 0, 0.25);
}

.customNodeBody .nodeLabel {
  position: absolute;
  left: 10px;
  right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.toolbar {
  white-space: nowrap;
}

.toolbar .ant-btn {
  border-radius: 0;
  width: 36px;
  border-left-width: 0;
}

.toolbar .ant-btn:hover {
  z-index: 1002;
}

.toolbar .ant-btn:first-child {
  /* border-top-left-radius: 16px;
  border-bottom-left-radius: 16px; */
  border-left-width: 1px;
}

/* .toolbar .ant-btn:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
} */

.subtypes .ant-tag {
  border-radius: 3px;
  margin: 0;
  width: 32px;
}

.ant-table-cell .ant-typography {
  margin-bottom: 0;
}

.ant-typography p {
  margin: 1em 0 0 0;
}

.ant-typography p:first-child {
  margin: 0;
}

.ant-typography p + ol,
.ant-typography p + ul {
  margin-top: 1em;
}

.ant-typography ul {
  list-style-type: disc;
}

.ant-rate .ant-rate-star-second {
  color: rgba(60, 61, 65, 0.25);
}

#assessment-table .ant-table-row .ant-table-cell:nth-child(2),
#assessment-table .ant-table-row .ant-table-cell:nth-child(3),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(2),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(3) {
  background-color: #ffccc7;
}

#assessment-table .ant-table-row .ant-table-cell:nth-child(4),
#assessment-table .ant-table-row .ant-table-cell:nth-child(5),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(4),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(5) {
  background-color: #ffffb8;
}

#assessment-table .ant-table-row .ant-table-cell:nth-child(6),
#assessment-table .ant-table-row .ant-table-cell:nth-child(7),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(6),
#assessment-table .ant-table-thead .ant-table-cell:nth-child(7) {
  background-color: #d9f7be;
}

#assessment-table .ant-table-row.select-row .ant-table-cell {
  background-color: #fbfbfb;
}

.expanded {
  background: #fff;
  position: absolute;
  top: 102px;
  left: 96px;
  bottom: 16px;
  right: 16px;
  overflow-y: auto;
}

span.ant-slider-mark-text.ant-slider-mark-text-active {
  min-width: 50px;
}

#graph-table .ant-table-wrapper .ant-table-selection-column {
  text-align: left !important;
}
