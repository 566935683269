/*
  CSS grid is great for making video layouts.
  For the purposes of this demo, we're keeping things relatively simple.
  If you're interested in dynamic video grids, make sure to check out this blog post:
   https://www.daily.co/blog/add-pagination-to-a-custom-daily-video-chat-app-to-support-larger-meetings/
 */
.call {
  display: grid;
  /* padding: 5rem; */
  align-items: center;
  justify-items: center;
  /* grid-template-columns: repeat(auto-fit, minmax(480px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  position: relative;
  height: calc(100vh / 2);
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-auto-rows: unset;
  }
}

.is-screenshare {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}

/*
  When someone is sharing their screen, we want to resize the participants' videos,
  so the biggest screen in our grid is the screen share.
 */
.is-screenshare .self-view {
  width: 240px;
  height: 135px;
}

/* Resize remote participants when a screen is being shared */
.is-screenshare .tile-video {
  width: 240px;
  height: 135px;
}

.info-box {
  background: var(--dark-blue-border);
  color: var(--white);
  /* width: 480px; */
  width: 240px;
  /* height: 270px; */
  height: calc(100vh / 2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: 240px;
    height: auto;
    padding: 2rem 1rem;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}