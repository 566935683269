.customNodeBody {
  width: 75px;
  height: 75px;
  /* border: 3px solid black; */
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}

/* .customNode:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  height: 15px;
  width: 15px;
  transform: translate(-50%, 0);
  background: #abab;
  z-index: 1000;
  line-height: 1;
  border-radius: 10px;
  color: #fff;
  font-size: 9px;
} */

.customNode .nodeDragHandle {
  position: absolute;
  top: 32px;
  z-index: 1001;
}

.customNode .nodeDragHandle svg {
  fill: #fff;
}

.react-flow .react-flow__handle.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}

.add-btn {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  margin: 10px;
}

.add-btn:hover {
  background: rgb(204, 217, 246) !important;
}

.customNodeBody {
  font-size: 7px;
}