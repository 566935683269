.home-screen {
  padding: 0 1rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: 514px;
}

.home-screen h1 {
  color: var(--white);
  font-weight: bold;
}

.home-screen p {
  color: var(--grey);
}
